// feature
import Layout from '@/components/Layout'
import styled from 'styled-components'
import { breakpointUp } from '@/utils/breakpoints'
import { yougo, yougoLink } from '@/feature/yougo'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// components
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { YougoList } from '@/components/YougoList'
import { YougoLinkList } from '@/components/YougoLinkList'

const IndexPage = props => (
  <Layout>
    <Seo page="yougo" title="か行" url="/yougo/ka/" />
    <LayoutContainer>
      <Yougo>
        <YougoLinkListContainer>
          <YougoLinkList linkList={yougoLink} />
        </YougoLinkListContainer>
        <YougoHeading>
          <HeadingLevel1>か行</HeadingLevel1>
        </YougoHeading>

        <Note>
          ※この用語集は専門書や解説書などを元に当社が独自に作成・編集したもので、他の用語集と内容が異なる場合があります。
        </Note>
        <YougoList titleList={yougo['ka']} link="/yougo/ka/" />
      </Yougo>
    </LayoutContainer>
  </Layout>
)

const YougoHeading = styled.div`
  margin-bottom: 2.4rem;
`

const YougoLinkListContainer = styled.div`
  margin-bottom: 4rem;
`

const Yougo = styled.div`
  ${breakpointUp('md')} {
    margin-left: auto;
    margin-right: auto;
    max-width: 72rem;
  }
`

const Note = styled.div`
  margin-bottom: 1.6rem;
  font-size: 1rem;
`

const LinkButtonContainer = styled.div`
  text-align: center;
  margin-top: 4rem;
`

export default IndexPage
